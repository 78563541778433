import { useEffect, useState } from "react";
import UsuarioService from "../service/UsuarioService";
import { toast } from 'react-toastify';
import useOperacionPlantaFase from "./useOperacionPlantaFase";

const useUsuarios = () => {
    const [usuarios, setUsuarios] = useState();
    const [selectOneUsuarios, setSelectOneUsuarios] = useState();
    const [auxiliaresForPlanta, setAuxiliaresForPlanta] = useState([]) //-> estado que contiene auxiliares

    const [campoAuxiliar, setCampoAuxiliar] = useState('')
    const [auxiliares, setAuxiliares] = useState([]) // -> auxiliares seleccionados en el form

    const [campoPlantaDependencia, setCampoPlantaDependencia] = useState('')

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { crearUsuarios, listarUsuarios, updateUsuarios, saberSiEstaOcupado, createUserMasivo, getUsuariosForPlanta } = UsuarioService();
    const { cerrarFase3H } = useOperacionPlantaFase()

    const onChangeCampoPlantaDependencia = (e) => {
        setCampoPlantaDependencia(e.target.value)
    }

    /* este es el onchange del input */
    const handleCampoAuxiliar = (e) => {
        setCampoAuxiliar(e.target.value)
    }

    /* esta es la funcion que se ejecuta cuando se presiona el boton agregar auxiliar */
    const handleAuxiliares = async () => {

        try {

            setCampoAuxiliar('')
            /* 2032  */
            if (auxiliares.length >= 12) {
                toast.error('no se pueden elegir mas de 12 auxiliares por operacion')
                return // que no ingrese mas de seis auxiliares
            }

            if (campoAuxiliar === '') {
                toast.error('el campo no debe estar vacio')
                return // que no lo ingrese vacio
            }

            if (validateIfExistAuxiliar(parseInt(campoAuxiliar))) {
                toast.error('ya fue elegido el auxiliar para esta operacion')
                return // que no se repita en la tabla
            }

            setLoading(true)
            const estaOcupado = await saberSiEstaOcupadoH({ ID_USUARIO: parseInt(campoAuxiliar) })
            console.log(estaOcupado);

            if (estaOcupado.length > 0) {
                toast.error(`Auxiliar esta ocupado en otra operación, ${estaOcupado[0]?.CORRELATIVO}`)
                return
            }

            const seleccionado = await getOne(parseInt(campoAuxiliar)) //filtrar del objeto padre
            setAuxiliares(prevState => [...prevState, seleccionado]) // guardar en el estado que servira para la tabla de seleccionados auxiliares
            toast.success('Auxiliar agregado')
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const validateIfExistAuxiliar = (ID) => {
        const isExist = auxiliares.some((item) => item.ID === ID) // verificar si existe
        return isExist
    }

    const addAuxiliaresSeleccionados = (auxiliaresSelecionados) => {

        const seleccionados = usuarios.filter((aAux) => {
            return auxiliaresSelecionados.some((sAux) => parseInt(sAux.cedula) === aAux.NUMERO_IDENTIFICACION)
        })

        setAuxiliares(prev => [...seleccionados])
    }

    const deleteAuxiliar = (ID) => {
        setAuxiliares(auxiliares.filter(auxiliar => auxiliar.ID !== ID)) // eliminar auxiliar de la tabla
    }

    const saberSiEstaOcupadoH = async ({ ID_USUARIO }) => {
        try {
            setLoading(true)
            const response = await saberSiEstaOcupado({ ID_USUARIO });
            return (response?.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const registrarAuxiliares = async ({ GUID, ID_USER, ID_PLANTA_DEPENDENCIA }) => {

        if (auxiliares.length <= 0) {
            let error = 'no hay auxiliares para registrar'
            toast.error(error)
            throw new Error(error)
        }

        if (ID_PLANTA_DEPENDENCIA === '' && !ID_PLANTA_DEPENDENCIA) {
            let error = ('no hay planta dependencia')
            toast.error(error)
            throw new Error(error)
        }

        const aux = {
            AUXILIAR_1: "",
            AUXILIAR_2: "",
            AUXILIAR_3: "",
            AUXILIAR_4: "",
            AUXILIAR_5: "",
            AUXILIAR_6: "",
            AUXILIAR_7: "",
            AUXILIAR_8: "",
            AUXILIAR_9: "",
            AUXILIAR_10: "",
            AUXILIAR_11: "",
            AUXILIAR_12: ""
        }

        for (let i = 0; i < auxiliares.length && i < 12; i++) {
            aux[`AUXILIAR_${i + 1}`] = auxiliares[i].ID;
        }

        try {
            setLoading(true)
            await cerrarFase3H({ GUID, AUX: aux, ID_USER, ID_PLANTA_DEPENDENCIA })
        } catch (error) {
            throw new Error(error)
        } finally {
            setLoading(false)

        }


    }

    const list = async () => {
        try {
            setLoading(true)
            const response = await listarUsuarios();
            setUsuarios(response?.data || []);
        } catch (e) {
            setError(e);

        } finally {
            setLoading(false);
        }
    }

    const getOne = async (ID) => {
        try {
            const info = usuarios.find((usuario) => usuario.ID === ID);
            setSelectOneUsuarios(info);
            return info;
        } catch (error) {
            setError(error);
        }
    };

    const createUserMasivoH = async ({ usuarios }) => {
        try {
            setLoading(true)
            const response = await createUserMasivo({ usuarios })
            await list()
            return response
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    const cleanGetOne = () => setSelectOneUsuarios(null)

    const update = async ({ ID, ID_EMPRESA,
        ID_CLIENTE,
        ID_PLANTA,
        ID_ROL,
        NOMBRE,
        APELLIDO,
        ESTADO,
        CCDS_REGIONES_ID, EMAIL,
        PASSWORD,
        NUMERO_IDENTIFICACION,
        ID_CARGO,
        ID_TIPO_DE_IDENTIFICACION }) => {
        try {

            setLoading(true);
            const response = await updateUsuarios({
                ID, ID_EMPRESA,
                ID_CLIENTE,
                ID_PLANTA,
                ID_ROL,
                NOMBRE,
                APELLIDO,
                ESTADO,
                CCDS_REGIONES_ID, EMAIL,
                PASSWORD,
                NUMERO_IDENTIFICACION,
                ID_CARGO,
                ID_TIPO_DE_IDENTIFICACION
            });
            list();
            return response;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const create = async ({ ID_EMPRESA,
        ID_CLIENTE,
        ID_PLANTA,
        ID_ROL,
        NOMBRE,
        APELLIDO,
        ESTADO,
        CCDS_REGIONES_ID, EMAIL,
        PASSWORD,
        NUMERO_IDENTIFICACION,
        ID_CARGO,
        ID_TIPO_DE_IDENTIFICACION }) => {

        try {
            setLoading(true);
            const response = await crearUsuarios({
                ID_EMPRESA,
                ID_CLIENTE,
                ID_PLANTA,
                ID_ROL,
                NOMBRE,
                APELLIDO,
                ESTADO,
                CCDS_REGIONES_ID, EMAIL,
                PASSWORD,
                NUMERO_IDENTIFICACION,
                ID_CARGO,
                ID_TIPO_DE_IDENTIFICACION
            });
            list();
            return response;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }
    const UsuariosForPlantaSoloAux = async ({ ID_PLANTA }) => {
        const info = await getUsuariosForPlanta({ ID_PLANTA });
        return info
    }

    const listForForDate = async ({ FECHA, ID_PLANTA }) => {
        const info = await listarUsuarios({ FECHA, ID_PLANTA });
        return info
    }


    const getOneAuxiliaresForPlanta = async (ID_PLANTA) => {
        const info = await usuarios?.filter(usuario => usuario.ID_PLANTA === ID_PLANTA && (usuario.ID_CARGO === 10 || usuario.ID_CARGO === 2));
        setAuxiliaresForPlanta(info);
    }

    useEffect(() => {
        list();
    }, [])

    return ({
        usuarios, getOne, selectOneUsuarios, createUserMasivoH, update, create, loading, error, cleanGetOne, auxiliaresForPlanta, getOneAuxiliaresForPlanta, auxiliares,
        handleCampoAuxiliar, UsuariosForPlantaSoloAux, listForForDate,
        handleAuxiliares, campoAuxiliar, deleteAuxiliar, saberSiEstaOcupadoH, registrarAuxiliares, campoPlantaDependencia, onChangeCampoPlantaDependencia, addAuxiliaresSeleccionados
    })

}

export default useUsuarios

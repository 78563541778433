export const HOST = process.env.REACT_APP_API_URL;
export const WAPI = process.env.REACT_APP_WAPI_URL;
export const VERSION = 'api/v1/';

const APP_URL = {
    // AUTH
    LOGIN: `${HOST}${VERSION}auth/login/`,
    RECOVERYPASS: `${HOST}${VERSION}agent/recoveryPass/`,
    LISTADOTIPOSDOCUMENTOS: `${HOST}${VERSION}auth/listadoTiposIdentificacion/`,
    WAPI_ISLOGIN: `${WAPI}islogin/`,

    // PARAMETER
    LISTARREGIOSNESCLIENTE: `${HOST}${VERSION}admin/parametros/listarRegionesCliente/`,
    LISTARPLANTASREGIONCLIENTE: `${HOST}${VERSION}admin/parametros/listarPlantasRegionCliente/`,

    //OPERACIONES
    LISTAROPERACIONES: `${HOST}${VERSION}admin/operaciones/listarOperaciones/`,
    LISTARIMAGENESOPERACION: `${HOST}${VERSION}admin/operaciones/listarImagenesOperacion/`,
    VERDOCUMENTOEQUIVALENTE: `${HOST}${VERSION}admin/operaciones/verDocumentoEquivalente/`,
    LISTARIMAGENESRECIBO: `${HOST}${VERSION}admin/operaciones/listarImagenesRecibo/`,
    APROBAROPERACION: `${HOST}${VERSION}admin/operaciones/aprobarOperacion/`,
    DOCUMENTOEQUIVALENTE: `${HOST}${VERSION}admin/operaciones/documentoEquivalente/`,
    LISTARTIPOOPERACIONPLANTA: `${HOST}${VERSION}admin/operaciones/listarTipoOperacionPlanta/`,
    LISTARTODASPLANTASCLIENTE: `${HOST}${VERSION}admin/operaciones/listarTodasPlantasCliente/`,
    LISTARTRANSPORTISTATIPO: `${HOST}${VERSION}admin/operaciones/listarTransportistaTipo/`,
    LISTARTRANSPORTISTA: `${HOST}${VERSION}admin/operaciones/listarTransportista/`,
    LISTARTIPOTRANSPORTE: `${HOST}${VERSION}admin/operaciones/listarTipoTransporte/`,
    VEROPERACION: `${HOST}${VERSION}admin/operaciones/verOperacion/`,

    EDITAROPERACION: `${HOST}${VERSION}admin/operaciones/editarOperacion/`,

    GETFILTRO: `${HOST}${VERSION}admin/operaciones/getFiltro/`,
    getOperacionForUUID: `${HOST}${VERSION}admin/operaciones/getOperacionForUUID/`,

    //ACCIONES
    LISTARACCIONES: `${HOST}${VERSION}admin/acciones/`,
    UPDATEACCION: `${HOST}${VERSION}admin/acciones/update/`,
    CREATEACCION: `${HOST}${VERSION}admin/acciones/create/`,

    //CLIENTES
    LISTARCLIENTES: `${HOST}${VERSION}admin/clientes/`,
    UPDATECLIENTES: `${HOST}${VERSION}admin/clientes/update/`,
    CREATECLIENTES: `${HOST}${VERSION}admin/clientes/create/`,

    //CONTENIDOS
    LISTARCONTENIDOS: `${HOST}${VERSION}admin/contenidos/`,
    UPDATECONTENIDOS: `${HOST}${VERSION}admin/contenidos/update/`,
    CREATECONTENIDOS: `${HOST}${VERSION}admin/contenidos/create/`,

    //EMPRESAS
    LISTAREMPRESAS: `${HOST}${VERSION}admin/empresas/`,
    UPDATEEMPRESAS: `${HOST}${VERSION}admin/empresas/update/`,
    CREATEEMPRESAS: `${HOST}${VERSION}admin/empresas/create/`,

    // DEPENDENCIAS
    LISTARDEPENDENCIAS: `${HOST}${VERSION}admin/dependencias/`,
    UPDATEDEPENDENCIAS: `${HOST}${VERSION}admin/dependencias/update/`,
    CREATEDEPENDENCIAS: `${HOST}${VERSION}admin/dependencias/create/`,
    GETDEPENDENCIATOCREATE: `${HOST}${VERSION}admin/dependencias/getDependenciaToCreate/`,

    //EMPRESA-CLIENTE
    LISTAREMPRESA_CLIENTE: `${HOST}${VERSION}admin/empresa-cliente/`,
    UPDATEEMPRESA_CLIENTE: `${HOST}${VERSION}admin/empresa-cliente/update/`,
    CREATEEMPRESA_CLIENTE: `${HOST}${VERSION}admin/empresa-cliente/create/`,

    //ESTADOS
    LISTARESTADOS: `${HOST}${VERSION}admin/estados/`,
    UPDATEESTADOS: `${HOST}${VERSION}admin/estados/update/`,
    CREATEESTADOS: `${HOST}${VERSION}admin/estados/create/`,

    //JUSTIFICACIONES
    LISTARJUSTIFICACIONES: `${HOST}${VERSION}admin/justificaciones/`,
    UPDATEJUSTIFICACIONES: `${HOST}${VERSION}admin/justificaciones/update/`,
    CREATEJUSTIFICACIONES: `${HOST}${VERSION}admin/justificaciones/create/`,

    //PLANTA-FASE
    LISTARPLANTAFASE: `${HOST}${VERSION}admin/planta-fase/`,
    UPDATEPLANTAFASE: `${HOST}${VERSION}admin/planta-fase/update/`,
    getOPlantaFaseForGUID_OPE: `${HOST}${VERSION}admin/planta-fase/getOPlantaFaseForGUID_OPE/`,


    //REGIONES
    LISTARREGIONES: `${HOST}${VERSION}admin/regiones/`,
    UPDATEREGIONES: `${HOST}${VERSION}admin/regiones/update/`,
    CREATEREGIONES: `${HOST}${VERSION}admin/regiones/create/`,

    //DETALLEJUSTIFICACION
    CREATEDETALLEJUSTIFICACION: `${HOST}${VERSION}admin/detalleJustificacion/create/`,

    //DETALLE IMAGENES

    LISTDETALLEIMAGENES: `${HOST}${VERSION}admin/detalle-img/listImages/`,
    DELETEDETALLEIMAGENES: `${HOST}${VERSION}admin/detalle-img/deleteForGUID/`,


    //PLANTAS
    LISTARPLANTAS: `${HOST}${VERSION}admin/plantas/`,
    UPDATEPLANTAS: `${HOST}${VERSION}admin/plantas/update/`,
    CREATEPLANTAS: `${HOST}${VERSION}admin/plantas/create/`,

    //PLANTA-DEPENDENCIA
    LISTARPLANTA_DEPENDENCIA: `${HOST}${VERSION}admin/planta-dependencia/`,
    UPDATEPLANTA_DEPENDENCIA: `${HOST}${VERSION}admin/planta-dependencia/update/`,
    CREATEPLANTA_DEPENDENCIA: `${HOST}${VERSION}admin/planta-dependencia/create/`,
    GETONEFORPLANTAD: `${HOST}${VERSION}admin/planta-dependencia/getOneForPlantaD`,

    // ESTADO-SELLOS
    LISTARESTADOSELLOS: `${HOST}${VERSION}admin/estado-sellos/`,

    //ESTRATIFICADAS

    GETESTRATIFICADAS: `${HOST}${VERSION}admin/estratificadas/index`,


    //CONFORMIDADES
    LISTARCONFORMIDADES: `${HOST}${VERSION}admin/conformidad/`,

    //OPERACION PLANTA FASE
    CERRARFASE2: `${HOST}${VERSION}admin/operacionPlantaFase/cerrarFase2/`,
    CERRARFASE3: `${HOST}${VERSION}admin/operacionPlantaFase/cerrarFase3/`,
    CERRARFASE4: `${HOST}${VERSION}admin/operacionPlantaFase/cerrarFase4/`,
    CERRARFASE5: `${HOST}${VERSION}admin/operacionPlantaFase/cerrarFase5/`,
    CERRARFASE6: `${HOST}${VERSION}admin/operacionPlantaFase/cerrarFase6/`,
    CERRARFASE7: `${HOST}${VERSION}admin/operacionPlantaFase/cerrarFase7/`,
    UPLOADIMG: `${HOST}${VERSION}admin/operacionPlantaFase/uploadImg/`,
    GETONEOPF: `${HOST}${VERSION}admin/operacionPlantaFase/getOneOPF/`,

    //TIPOS GASTOS
    LISTARTIPOSGASTOS: `${HOST}${VERSION}admin/tiposGastos/`,
    UPDATETIPOSGASTOS: `${HOST}${VERSION}admin/tiposGastos/update/`,
    CREATETIPOSGASTOS: `${HOST}${VERSION}admin/tiposGastos/create/`,

    //INASISTENCIAS
    LISTINASISTENCIAS: `${HOST}${VERSION}admin/inasistencias/`,
    CREATEINASISTENCIAS: `${HOST}${VERSION}admin/inasistencias/create`,
    UPDATEINASISTENCIAS: `${HOST}${VERSION}admin/inasistencias/update`,
    REMOVEINASISTENCIA: `${HOST}${VERSION}admin/inasistencias/remove`,
    GETFORUSERINASISTENCIA: `${HOST}${VERSION}admin/inasistencias/getForUser`,
    CREATEARRAYINASISTENCIA: `${HOST}${VERSION}admin/inasistencias/createArray`,
    LISTFORDATEINASISTENCIA: `${HOST}${VERSION}admin/inasistencias/listForForDate`,
    CREATEDOCUMENTINASISTENCIA: `${HOST}${VERSION}admin/inasistencias/uploadDocument`,
    GETDOCUMENTFORINASISTENCIA: `${HOST}${VERSION}admin/inasistencias/getDocumentForInasistencia`,
    REMOVEDOCUMENTINASISTENCIA: `${HOST}${VERSION}admin/inasistencias/removeDocument`,
    GETREPORTINASISTENCIA: `${HOST}${VERSION}admin/inasistencias/getReport`,


    //TIPOS OPERACIONES
    LISTARTIPOSOPERACIONES: `${HOST}${VERSION}admin/tiposOperaciones/`,
    UPDATETIPOSOPERACIONES: `${HOST}${VERSION}admin/tiposOperaciones/update/`,
    CREATETIPOSOPERACIONES: `${HOST}${VERSION}admin/tiposOperaciones/create/`,
    GETIPOPERACIONFORPLANTA: `${HOST}${VERSION}admin/tiposOperaciones/getTipoOperacionForPlanta`,

    //tipoTransporte
    LISTARTIPOTRANSPORTE: `${HOST}${VERSION}admin/tipoTransporte/`,
    UPDATETIPOTRANSPORTE: `${HOST}${VERSION}admin/tipoTransporte/update/`,
    CREATETIPOTRANSPORTE: `${HOST}${VERSION}admin/tipoTransporte/create/`,

    //OPERACIONES
    CREATEOPERACION: `${HOST}${VERSION}admin/operaciones/create/`,
    CCDS_SP_ELIMINA_REGISTRO: `${HOST}${VERSION}admin/operaciones/CCDS_SP_ELIMINA_REGISTRO`,
    GETOPERACIONFORUUID2: `${HOST}${VERSION}admin/operaciones/getOperacionForUUID2/`,


    //transportistas
    LISTARTRANSPORTISTA: `${HOST}${VERSION}admin/transportistas/`,
    UPDATETRANSPORTISTA: `${HOST}${VERSION}admin/transportistas/update/`,
    CREATETRANSPORTISTA: `${HOST}${VERSION}admin/transportistas/create/`,

    //unidades
    LISTARUNIDADES: `${HOST}${VERSION}admin/unidades/`,
    UPDATEUNIDADES: `${HOST}${VERSION}admin/unidades/update/`,
    CREATEUNIDADES: `${HOST}${VERSION}admin/unidades/create/`,

    //cargos
    LISTARCARGOS: `${HOST}${VERSION}admin/cargos/`,
    UPDATECARGOS: `${HOST}${VERSION}admin/cargos/update/`,
    CREATECARGOS: `${HOST}${VERSION}admin/cargos/create/`,

    //modulos
    LISTARMODULOS: `${HOST}${VERSION}admin/modulos/`,
    UPDATEMODULOS: `${HOST}${VERSION}admin/modulos/update/`,
    CREATEMODULOS: `${HOST}${VERSION}admin/modulos/create/`,

    //roles
    LISTARROLES: `${HOST}${VERSION}admin/roles/`,
    UPDATEROLES: `${HOST}${VERSION}admin/roles/update/`,
    CREATEROLES: `${HOST}${VERSION}admin/roles/create/`,

    //rol-modulo
    LISTARROL_MODULO: `${HOST}${VERSION}admin/rol-modulo/`,
    UPDATEROL_MODULO: `${HOST}${VERSION}admin/rol-modulo/update/`,
    CREATEROL_MODULO: `${HOST}${VERSION}admin/rol-modulo/create/`,

    //usuarios
    LISTARUSUARIOS: `${HOST}${VERSION}admin/usuarios/`,
    UPDATEUSUARIOS: `${HOST}${VERSION}admin/usuarios/update/`,
    CREATEUSUARIOS: `${HOST}${VERSION}admin/usuarios/create/`,
    SABERSIESTAOCUPADO: `${HOST}${VERSION}admin/usuarios/saberSiEstaOcupado/`,
    CREATEUSERMASIVO: `${HOST}${VERSION}admin/usuarios/createUserMasivo/`,
    GETFORPLANTAUSUARIOSOLOPLANTA: `${HOST}${VERSION}admin/usuarios/getForPlantaSoloAuxiliares`,

    //VALUDAR OPERACIONES
    VALIDAR: `${HOST}${VERSION}admin/validarOperaciones/validar`,
    LEGALIZAR: `${HOST}${VERSION}admin/validarOperaciones/legalizar`,


    //tipoIdentificacion
    LISTARTIPOIDENTIFICACION: `${HOST}${VERSION}admin/tipoIdentificacion/`,
    UPDATETIPOIDENTIFICACION: `${HOST}${VERSION}admin/tipoIdentificacion/update/`,
    CREATETIPOIDENTIFICACION: `${HOST}${VERSION}admin/tipoIdentificacion/create/`,

    LISTPARAMETER: `${HOST}${VERSION}admin/parameter/list/`,
    SEARCHTYPECATEGORY: `${HOST}${VERSION}admin/parameter/searchtypecategory/`,
    CREATEPARAMETER: `${HOST}${VERSION}admin/parameter/create/`,
    DELETEPARAMETER: `${HOST}${VERSION}admin/parameter/deleteparameter/`,
    LISTREQUESTFORTYPE: `${HOST}${VERSION}admin/parameter/listRequestForType/`,

    // USERS
    LISTUSERS: `${HOST}${VERSION}admin/users/list/`,
    LISTUSERSAUTOCOMPLETE: `${HOST}${VERSION}admin/users/listUserAutocomplete/`,
    CREATEUSERS: `${HOST}${VERSION}admin/users/create/`,
    UPDATEUSERS: `${HOST}${VERSION}admin/users/update/`,
    UPDATESTATUS: `${HOST}${VERSION}admin/users/updateStatus/`,
    UPDATEPASS: `${HOST}${VERSION}admin/users/updatePass/`,

    // FASES
    LISTFASES: `${HOST}${VERSION}admin/fases/`,
    UPDATEFASES: `${HOST}${VERSION}admin/fases/update/`,
    CREATEFASES: `${HOST}${VERSION}admin/fases/create/`,

    //POWERBI
    GETALLPOWERBI: `${HOST}${VERSION}admin/powerbi/`,
    GETONEPOWERBI: `${HOST}${VERSION}admin/powerbi/getOne`,
    CREATEPOWERBI: `${HOST}${VERSION}admin/powerbi/create`,
    UPDATEPOWERBI: `${HOST}${VERSION}admin/powerbi/update`,
    DELETEPOWERBI: `${HOST}${VERSION}admin/powerbi/delete`,
    GETBYIDPLANTAPOWEBI: `${HOST}${VERSION}admin/powerbi/getByIdPlanta`,



    //tickets
    LISTTICKETS: `${HOST}${VERSION}admin/tickets/list/`,
    LISTTICKETSTYPE: `${HOST}${VERSION}admin/tickets/listType/`,
    LISTTICKETSUSER: `${HOST}${VERSION}admin/tickets/listUser/`,
    LISTTICKETSUSERSTATUS: `${HOST}${VERSION}admin/tickets/listUserStatus/`,
    SEARCHPRIORITY: `${HOST}${VERSION}admin/tickets/searchPriority/`,
    CREATETICKET: `${HOST}${VERSION}admin/tickets/create/`,
    DETAILSTICKET: `${HOST}${VERSION}admin/tickets/listDetails/`,
    QUALIFICATIONTICKET: `${HOST}${VERSION}admin/tickets/listQualification/`,
    SAVEQUALIFICATION: `${HOST}${VERSION}admin/tickets/saveQualification/`,
    CREATEDETAILSTICKET: `${HOST}${VERSION}admin/tickets/createDetails/`,
    LISTUPLOADSTICKET: `${HOST}${VERSION}admin/tickets/listUpload/`,
    CREATEUPLOADTICKETS: `${HOST}${VERSION}admin/tickets/createUpload/`,
    EXPORTDATA: `${HOST}${VERSION}admin/tickets/exportData`,
    DELETEFILESXLXS: `${HOST}${VERSION}admin/tickets/deleteFileXLXS`,
    FILTERDATATICKETS: `${HOST}${VERSION}admin/tickets/FilterDataTickets`,
    TOTALTICKETS: `${HOST}${VERSION}admin/tickets/totalTickets`,

    // MODULES
    LISTMODULES: `${HOST}${VERSION}admin/modules/list/`,
    CREATEMODULES: `${HOST}${VERSION}admin/modules/create/`,
    UPDATEMODULES: `${HOST}${VERSION}admin/modules/update/`,
    UPDATESTATUSMODULES: `${HOST}${VERSION}admin/modules/updatestatus/`,
    // PRODUCT
    LISTPRODUCT: `${HOST}${VERSION}admin/modules/listProduct/`,
    UPDATEPRODUCT: `${HOST}${VERSION}admin/modules/updateproduct/`,
    UPDATESTATUSPRODUCT: `${HOST}${VERSION}admin/modules/updatestatusproduct/`,

    // STUDENTS
    CREATESTUDENTS: `${HOST}${VERSION}admin/students/createstudents/`,
    LISTSTUDENTS: `${HOST}${VERSION}admin/students/liststudents/`,
    LISTSTUDENTSREGISTRATION: `${HOST}${VERSION}admin/students/liststudentregistration/`,
    UPDATESTUDENTS: `${HOST}${VERSION}admin/students/updatestudents/`,
    // STUDENTS REGISTRATION
    SEARCHSTUDENTS: `${HOST}${VERSION}admin/students/searchstudent/`,
    SEARCHCOURSE: `${HOST}${VERSION}admin/students/searchcourse/`,
    SEARCHCOURSEDESCRIPTION: `${HOST}${VERSION}admin/students/searchcoursedescription/`,
    STUDENTENROLLMENT: `${HOST}${VERSION}admin/students/studentenrollment/`,
    LISTTEACHERENROLLMENT: `${HOST}${VERSION}admin/students/listteacherenrollment/`,
    UPLOADFILEPRINT: `${HOST}${VERSION}admin/students/uploadFingerPrint/`,
    LISTSTUDENTSAUTOCOMPLETE: `${HOST}${VERSION}admin/students/liststudentsAutoComplete/`,
    SEARCHREGISTRATION: `${HOST}${VERSION}admin/students/searchregistration/`,
    SEARCHREGISTRATIONSTUDENTS: `${HOST}${VERSION}admin/students/searchregistrationstudents/`,
    UPDATEREGISTRATIONSTUDENTS: `${HOST}${VERSION}admin/students/updateregistrationstudents/`,

    // TEACHER
    LISTTEACHER: `${HOST}${VERSION}admin/teacher/listTeacher/`,
    CREATETEACHER: `${HOST}${VERSION}admin/teacher/create/`,
    UPDATETEACHER: `${HOST}${VERSION}admin/teacher/update/`,
    LISTTEACHERAUTOCOMPLETE: `${HOST}${VERSION}admin/teacher/listTeacherAutoComplete/`,
    // TEACHER REGISTRATION
    SEARCHTYPECATEGORYTEACHER: `${HOST}${VERSION}admin/parameter/searchtypecategoryteacher/`,
    SEARCHTEACHER: `${HOST}${VERSION}admin/teacher/searchTeacher/`,
    LISTMODULETEACHER: `${HOST}${VERSION}admin/teacher/listModuleTeacher/`,
    DELETEMODULETEACHER: `${HOST}${VERSION}admin/teacher/deleteModuleTeacher/`,
    MODULETEACHER: `${HOST}${VERSION}admin/teacher/moduleTeacher/`,
    // LIST ESTUDENTS TEACHER
    LISTSTUDENTSTEACHER: `${HOST}${VERSION}admin/teacher/listestudentsteacher/`,
    SAVEEVIDENCECOURSE: `${HOST}${VERSION}admin/teacher/saveevidencecourse/`,
    SEARCHEVIDENCE: `${HOST}${VERSION}admin/teacher/searchevidence/`,
    SEARCHSESSION: `${HOST}${VERSION}admin/teacher/searchsession/`,
    UPDATESTATUSEVIDENCE: `${HOST}${VERSION}admin/teacher/updatestatusevidence/`,

    // REPORT
    LISTSTUDENTHOURS: `${HOST}${VERSION}admin/reports/listStudentHours/`,
    LISTSTUDENTDATE: `${HOST}${VERSION}admin/reports/listStudentDate/`,
    LISTSTUDENTHOURSID: `${HOST}${VERSION}admin/reports/listStudentHoursid/`,
    // ATTENDANT
    SEARCHATTENDANT: `${HOST}${VERSION}admin/attendant/searchattendant/`,

    //NOTIFY
    CREATENOTIFYALL: `${HOST}${VERSION}admin/parameter/notifyWhastsappAll/`,
    CREATENOTIFYMODULE: `${HOST}${VERSION}admin/parameter/notifyWhastsappModule/`,
    CREATENOTIFYINDIVIDUAL: `${HOST}${VERSION}admin/parameter/notifyWhastsappIndividual/`,
    CREATENOTIFYPRIVATE: `${HOST}${VERSION}admin/parameter/notifyWhastsappPrivate/`,
    LISTNOTIFYALL: `${HOST}${VERSION}admin/parameter/listNotifyWhastsapp/`,
    LISTNOTIFYWHATSAPPATTENDANT: `${HOST}${VERSION}admin/parameter/listNotifyWhastsappAttendant/`,
}

export default APP_URL




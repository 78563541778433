import { useEffect, useState } from "react";
import PlantaFaseService from "../service/planta-faseService";

const usePlantaFase = (GUID_OPE = null) => {
    const [plantaFases, setPlantaFases] = useState();
    const [selectOnePlantaFase, setSelectOnePlantaFase] = useState();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [plantasFasesForOpe, setPlantaFaseForOpe] = useState()

    const { listPlantaFase, updatePlantaFase, getOPlantaFaseForGUID_OPE } = PlantaFaseService();

    const list = async () => {
        try {
            setLoading(true)
            const response = await listPlantaFase();
            setPlantaFases(response?.data);
        } catch (e) {
            setError(e);

        } finally {
            setLoading(false);
        }
    }


    const update = async ({ ID, ID_PLANTA,
        ID_FASE,
        ORDEN,
        TIEMPO_MIN_ESTIMADO,
        ESTADO }) => {
        try {
            setLoading(true);
            const response = await updatePlantaFase({
                ID, ID_PLANTA,
                ID_FASE,
                ORDEN,
                TIEMPO_MIN_ESTIMADO,
                ESTADO
            });
            list();
            return response;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const getOneOPlantaFaseForGUID_OPE = async ({ GUID_OPE }) => {
        try {
            setLoading(true);
            const response = await getOPlantaFaseForGUID_OPE({ GUID_OPE });
            setPlantaFaseForOpe(response?.data);

        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }


    const getOne = async (ID) => {
        const info = plantaFases?.find(plantaFases => plantaFases.ID_PLANTA_FASE === ID);
        setSelectOnePlantaFase(info);
    }

    const cleanGetOne = () => setSelectOnePlantaFase(null)

    useEffect(() => {
        if (GUID_OPE) getOneOPlantaFaseForGUID_OPE({ GUID_OPE })
        else list()

    }, [])

    return { plantaFases, selectOnePlantaFase, loading, error, list, update, getOne, cleanGetOne, plantasFasesForOpe, getOneOPlantaFaseForGUID_OPE }

}

export default usePlantaFase
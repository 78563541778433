import { useState, useEffect } from 'react'
import DetalleImg from '../service/DetalleImgService'

const useDetalleImg = (GUID_OPE_PLAN_FASE) => {

    const [detalleImgs, setDetalleImgs] = useState([])
    const [saveParams, setSaveParams] = useState({})
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const { listImagesService, deleteForGUIDService } = DetalleImg()

    const listDetalleImage = async ({ GUID_OPE_PLAN_FASE }) => {
        try {
            setLoading(true)

            const response = await listImagesService({ GUID_OPE_PLAN_FASE })
            setDetalleImgs(response?.data)
            setSaveParams({ GUID_OPE_PLAN_FASE })

        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    const reFetch = () => {
        listDetalleImage(saveParams)
    }

    const deleteForGUID = async ({ GUID }) => {
        try {
            setLoading(true)
            await deleteForGUIDService({ GUID })
        } catch (error) {
            setError(error)
            throw new Error(error)
        } finally {
            setLoading(false)
            listDetalleImage({ GUID_OPE_PLAN_FASE })
        }
    }

    useEffect(() => {
        if (GUID_OPE_PLAN_FASE) {
            listDetalleImage({ GUID_OPE_PLAN_FASE })
        }
    }, [])

    return {
        listDetalleImage,
        detalleImgs,
        loading,
        error,
        deleteForGUID,
        reFetch
    }

}

export default useDetalleImg
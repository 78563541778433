import React, { useContext } from 'react';
import { createContext } from "react";
import useOperacion from "../hooks/useOperacion";

export const ContextOperacion = createContext();

export const ContextOperacionProvider = ({ children }) => {

    const { create, error, loading, listGetFiltro, operaciones, metrics, operacionesForUUID, getForUUID, eliminarOperacion, reGetFiltro } = useOperacion();

    return (
        <ContextOperacion.Provider value={{ create, error, loading, listGetFiltro, operaciones, metrics, operacionesForUUID, getForUUID, eliminarOperacion, reGetFiltro }}>
            {children}
        </ContextOperacion.Provider>
    );
};

export default ContextOperacionProvider;

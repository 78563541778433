import React, { createContext, useContext, useState } from 'react'
import useEstratificadas from '../hooks/useEstratificadas'
export const ContextExtratificada = createContext()

const ContextExtratificadaProvider = ({ children }) => {

    const { error, loading, estratificadas } = useEstratificadas()

    return (
        <ContextExtratificada.Provider value={{ estratificadas, error, loading }} >
            {children}
        </ContextExtratificada.Provider>
    )
}

export const useEstrtificadaProvider = () => {
    return useContext(ContextExtratificada)
}

export default ContextExtratificadaProvider
import { useEffect, useMemo, useState } from "react";
import OperacionesService from "../service/OperacionesService";


const useOperacion = (uuid = null) => {

    const [operaciones, setOperaciones] = useState();
    const [metrics, setMetrics] = useState();
    const [operacionesForUUID, setOperacionesForUUID] = useState();
    const [paramsListFiltro, setParamsListFiltro] = useState();


    const [planta, setPlanta] = useState()

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { createOperation, getFiltro, getOperacionForUUID, CCDS_SP_ELIMINA_REGISTRO } = OperacionesService()


    const eliminarOperacion = async (UUID, filters) => {
        try {
            setLoading(true);
            const response = await CCDS_SP_ELIMINA_REGISTRO(UUID);
            return response;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const create = async ({
        ID_USUARIO,
        ID_DEPENDENCIA,
        ID_TIPO_DE_OPERACION,
        NRO_TONELADAS,
        ID_PLANTA_DESTINO,
        ID_TRANSPORTISTA,
        PLACA,
        CONTENEDOR,
        ID_TIPO_TRANSPORTE,
        FECHO_INICIO,
        FECHO_FIN
    }) => {

        try {
            setLoading(true);
            setOperaciones(null)
            const response = await createOperation({
                ID_USUARIO,
                ID_DEPENDENCIA,
                ID_TIPO_DE_OPERACION,
                NRO_TONELADAS,
                ID_PLANTA_DESTINO,
                ID_TRANSPORTISTA,
                PLACA,
                CONTENEDOR,
                ID_TIPO_TRANSPORTE,
                FECHO_INICIO,
                FECHO_FIN
            });

            return response;
        } catch (error) {
            setError(error);
        } finally {
            await listGetFiltro({
                REGION: 0,
                PLANTA: 0,
                FECHADESDE: new Date(),
                FECHAHASTA: new Date(),
                FILTROS: 0,
                PLACA: ""
            })
            setLoading(false)
        }
    }

    const listGetFiltro = async ({ REGION,
        PLANTA,
        FECHADESDE,
        FECHAHASTA,
        FILTROS,
        PLACA, plantaUsuario }) => {
        try {
            if (!planta) {
                setPlanta(plantaUsuario)
            }

            let selectedPlanta

            /* SI NO SE SELECCIONA NADA SE DEJA LA PLANTA DEL USUARIO */
            if (PLANTA === "-" || !PLANTA) {
                selectedPlanta = planta
            } else if (PLANTA === "0") {
                //SI VIENE "0" ENTONCES QUIERE TODO
                selectedPlanta = 0
            } else {
                //SINO ENTONCES SE TOMA LA PLANTA ELEGIDA
                selectedPlanta = PLANTA
            }

            setLoading(true);
            const response = await getFiltro({
                REGION,
                PLANTA: selectedPlanta || plantaUsuario,
                FECHADESDE,
                FECHAHASTA,
                FILTROS,
                PLACA
            });
            getMetrics()
            setOperaciones(response?.data);
            setParamsListFiltro({ REGION, PLANTA, FECHADESDE, FECHAHASTA, FILTROS, PLACA })


        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)

        }
    }


    const reGetFiltro = async () => {
        await listGetFiltro(paramsListFiltro)
    }

    const getMetrics = async () => {

        const credito = operaciones?.filter(item => item.TIPO_RECIBO?.toLowerCase() === "credito") || []
        const efectivo = operaciones?.filter(item => item.TIPO_RECIBO?.toLowerCase() === "efectivo") || []
        const transferencia = operaciones?.filter(item => item.TIPO_RECIBO?.toLowerCase() === "transferencia") || []

        setMetrics({
            operaciones: operaciones?.length || 0,

            credito: credito?.length || 0,
            montoCredito: credito?.reduce((acc, item) => acc + item?.MONTO, 0) || 0,

            efectivo: efectivo?.length || 0,
            montoEfectivo: efectivo?.reduce((acc, item) => acc + item?.MONTO, 0) || 0,

            tranferencia: transferencia?.length || 0,
            montoTransferencia: transferencia?.reduce((acc, item) => acc + item?.MONTO, 0) || 0,

            gastos: 0
        })
    }

    const getOneOperacionForUUID = async (UUID) => {
        setOperacionesForUUID(null)
        try {
            setLoading(true);
            const response = await getOperacionForUUID(UUID);
            setOperacionesForUUID(response)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const getForUUID = async (uuid) => {
        await getOneOperacionForUUID(uuid)
    }

    useMemo(() => {
        getMetrics()
    }, [operaciones])

    useEffect(() => {
        if (uuid) {
            getOneOperacionForUUID(uuid)
        }
    }, [])


    return { create, error, loading, listGetFiltro, operaciones, metrics, operacionesForUUID, getForUUID, eliminarOperacion, reGetFiltro }
}

export default useOperacion
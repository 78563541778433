import URL_ROUTE from "../constants/index.js"
let token = sessionStorage.getItem('token');


const EstratificadasServce = () => {

    const getEstratificadas = async ({ ID_USER }) => {
        try {
            if (!token) return
            const response = await fetch(URL_ROUTE.GETESTRATIFICADAS, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ID_USER: 2023 })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    }

    return { getEstratificadas }
}

export default EstratificadasServce

import { useState } from "react";
import OperacionPlantaFaseService from "../service/OperacionPlantaFaseService";
import useDetalleImg from "./useDetalleImg";



const useOperacionPlantaFase = () => {
    const { cerrarFase2, cerrarFase4, cerrarFase5, cerrarFase6, cerrarFase7, cerrarFase3, uploadImg } = OperacionPlantaFaseService()
    const { listDetalleImage } = useDetalleImg()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)



    const cerrarFase2H = async ({ GUID, ID_USER }) => {
        try {
            setLoading(true)
            const res = await cerrarFase2({ GUID, ID_USER })

            return res
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const uploadImgH = async ({ IMG, GUID, fase6 = false, correlativo }) => {
        try {
            setLoading(true);

            const formData = new FormData();
            formData.append('GUID', GUID);
            formData.append('FASE6', fase6);
            formData.append('CORRELATIVO', correlativo);

            for (let i = 0; i < IMG.length; i++) {
                formData.append('upload', IMG[i]);
            }

            const res = await uploadImg({ formData });
            await listDetalleImage({ GUID_OPE_PLAN_FASE: GUID });
            return res;
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };



    const cerrarFase3H = async ({ GUID, AUX, ID_USER, ID_PLANTA_DEPENDENCIA }) => {
        try {
            setLoading(true)
            const res = await cerrarFase3({ GUID, AUX, ID_USER, ID_PLANTA_DEPENDENCIA })

            return res
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }


    const cerrarFase4H = async ({ GUID, ID_USER }) => {
        try {
            setLoading(true)
            const res = await cerrarFase4({ GUID, ID_USER })


            return res
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const cerrarFase5H = async ({ ID_CONTENIDO, ID_UNIDAD_FALTANTE, CANTIDAD_SEGUN_REMISION, OBSERVACIONES, CANTIDAD_FALTANTE, CANTIDAD_AVERIADA, ID_ESTADO_SELLADOS, ID_ARRUME, ID_AMARRE, ID_TIPO_IMAGEN, REFERENCIA, GUID, ID_USER, correlativo }) => {
        try {
            setLoading(true)
            const res = await cerrarFase5({ ID_CONTENIDO, ID_UNIDAD_FALTANTE, CANTIDAD_SEGUN_REMISION, OBSERVACIONES, CANTIDAD_FALTANTE, CANTIDAD_AVERIADA, ID_ESTADO_SELLADOS, ID_ARRUME, ID_AMARRE, ID_TIPO_IMAGEN, REFERENCIA, GUID, ID_USER, correlativo })


            return res
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const cerrarFase6H = async ({ recibiDe, cedulaDe, email, tipoDePago, referencia, upload, GUID, ID_USER, correlativo }) => {
        try {
            setLoading(true)
            const res = await cerrarFase6({ recibiDe, cedulaDe, email, tipoDePago, referencia, upload, GUID, ID_USER, correlativo })


            return res

        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    const cerrarFase7H = async ({ idAdicional,
        IdAccion,
        IdTipoDeOperacion,
        montoAdicional,
        GUID,
        ID_USER, }) => {
        try {
            setLoading(true)
            const res = await cerrarFase7({
                idAdicional,
                IdAccion,
                IdTipoDeOperacion,
                montoAdicional,
                GUID,
                ID_USER,
            })


            return res
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false)
        }
    }

    return { cerrarFase2H, data, loading, error, cerrarFase4H, cerrarFase5H, cerrarFase6H, cerrarFase7H, cerrarFase3H, uploadImgH }
}

export default useOperacionPlantaFase
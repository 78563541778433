import { useState, useEffect } from 'react'
import EstratificadasService from "../service/EstratificadasServce";
import { useEstrtificadaProvider } from "../context/ContextExtratificadas"
const { getEstratificadas } = EstratificadasService()

const useEstratificadas = () => {
    const [estratificadas, setEstratificadas] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const user = JSON.parse(sessionStorage.getItem("user"));
    const usuario_id = user?.idUsuario;
    const ID_USER = usuario_id

    const getEstratificadasH = async () => {
        try {
            setLoading(true)
            const response = await getEstratificadas({ ID_USER })
            setEstratificadas(response)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getEstratificadasH({ ID_USER })
    }, [])

    return { estratificadas, loading, error, getEstratificadasH }
}

export default useEstratificadas
import URL_ROUTE from "../constants/index.js"
let token = sessionStorage.getItem('token');
const DetalleImg = () => {


  const listImagesService = async ({ GUID_OPE_PLAN_FASE }) => {
    try {

      const response = await fetch(URL_ROUTE.LISTDETALLEIMAGENES, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          GUID_OPE_PLAN_FASE: GUID_OPE_PLAN_FASE
        })
      })

      return await response.json();

    } catch (error) {
      console.error(error.message);
    }
  }

  const deleteForGUIDService = async ({ GUID }) => {
    const response = await fetch(URL_ROUTE.DELETEDETALLEIMAGENES, {
      method: "DELETE",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({
        GUID: GUID
      })
    })

    return await response
  }

  return { listImagesService, deleteForGUIDService }

}

export default DetalleImg
import React, { createContext } from "react";
import useOperacionPlantaFase from "../hooks/useOperacionPlantaFase";

export const ContextOperacionPlantaFase = createContext()

export const ContextOperacionPlantaFaseProvider = ({ children }) => {

    const { cerrarFase2H, data, loading, error, cerrarFase4H, cerrarFase5H, cerrarFase7H, uploadImgH } = useOperacionPlantaFase()
    return (
        <ContextOperacionPlantaFase.Provider value={{ cerrarFase2H, data, loading, error, cerrarFase4H, cerrarFase5H, cerrarFase7H, uploadImgH }}>
            {children}
        </ContextOperacionPlantaFase.Provider>
    )
}
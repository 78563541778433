import React, { createContext } from "react";
import useUsuarios from "../hooks/useUsuarios";

export const ContextUsuarios = createContext()

export const ContextUsuariosProvider = ({ children }) => {
    const { usuarios, getOne, selectOneUsuarios, createUserMasivoH, update, create, loading, error, cleanGetOne, auxiliaresForPlanta, getOneAuxiliaresForPlanta, auxiliares,
        handleCampoAuxiliar, handleAuxiliares, campoAuxiliar, deleteAuxiliar, saberSiEstaOcupadoH, registrarAuxiliares, campoPlantaDependencia, onChangeCampoPlantaDependencia, addAuxiliaresSeleccionados } = useUsuarios()
    return (
        <ContextUsuarios.Provider value={{
            usuarios, getOne, selectOneUsuarios, update, createUserMasivoH, create, loading, error, cleanGetOne, auxiliaresForPlanta, getOneAuxiliaresForPlanta, auxiliares,
            handleCampoAuxiliar,
            handleAuxiliares, campoAuxiliar, deleteAuxiliar, saberSiEstaOcupadoH, registrarAuxiliares, campoPlantaDependencia, onChangeCampoPlantaDependencia, addAuxiliaresSeleccionados
        }}>
            {children}
        </ContextUsuarios.Provider>
    )

}

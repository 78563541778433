import URL_ROUTE from "../constants/index.js"
let token = sessionStorage.getItem('token');


const plantaFase = () => {

    const listPlantaFase = async () => {
        if (!token) return
        const response = await fetch(URL_ROUTE.LISTARPLANTAFASE, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return await response.json();
    }

    const getOPlantaFaseForGUID_OPE = async ({ GUID_OPE }) => {
        const response = await fetch(URL_ROUTE.getOPlantaFaseForGUID_OPE, {
            method: 'post',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ GUID_OPE })
        })
        return await response.json();
    }


    const updatePlantaFase = async ({ ID, ID_PLANTA,
        ID_FASE,
        ORDEN,
        TIEMPO_MIN_ESTIMADO,
        ESTADO }) => {
        try {
            const response = await fetch(URL_ROUTE.UPDATEPLANTAFASE, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    ID, ID_PLANTA,
                    ID_FASE,
                    ORDEN,
                    TIEMPO_MIN_ESTIMADO,
                    ESTADO
                })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    };

    return {
        listPlantaFase, updatePlantaFase, getOPlantaFaseForGUID_OPE
    }
}

export default plantaFase
import URL_ROUTE from "../constants/index.js"
let token = sessionStorage.getItem('token');


const OperacionPlantaFaseService = () => {

    const cerrarFase2 = async ({ GUID, ID_USER }) => {
        try {
            const response = await fetch(URL_ROUTE.CERRARFASE2, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ GUID, ID_USER })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    }
    const cerrarFase3 = async ({ AUX, GUID, ID_USER, ID_PLANTA_DEPENDENCIA }) => {

        try {
            const response = await fetch(URL_ROUTE.CERRARFASE3, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ AUX, GUID, ID_USER, ID_PLANTA_DEPENDENCIA })
            });

            return await response.json();
        } catch (error) {

            console.error(error.message);
            throw error;
        }
    }

    const getOneOPF = async (GUID) => {
        try {
            const response = await fetch(URL_ROUTE.GETONEOPF, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ GUID })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    }

    const uploadImg = async ({ formData }) => {
        try {
            const response = await fetch(URL_ROUTE.UPLOADIMG, {
                body: formData,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error
        }
    }

    const cerrarFase4 = async ({ GUID, ID_USER }) => {
        try {
            const response = await fetch(URL_ROUTE.CERRARFASE4, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ GUID, ID_USER })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    }

    const cerrarFase5 = async (data) => {
        try {

            const response = await fetch(URL_ROUTE.CERRARFASE5, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error
        }
    }
    const cerrarFase6 = async (data) => {
        try {
            const response = await fetch(URL_ROUTE.CERRARFASE6, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            })
            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error
        }
    }

    const cerrarFase7 = async ({ idAdicional,
        IdAccion,
        IdTipoDeOperacion,
        montoAdicional,
        GUID,
        ID_USER }) => {

        try {
            const response = await fetch(URL_ROUTE.CERRARFASE7, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    idAdicional,
                    IdAccion,
                    IdTipoDeOperacion,
                    montoAdicional,
                    GUID,
                    ID_USER,
                })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error
        }
    }

    return { cerrarFase2, cerrarFase4, cerrarFase5, cerrarFase6, cerrarFase7, cerrarFase3, uploadImg, getOneOPF }
}

export default OperacionPlantaFaseService
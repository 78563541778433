export const SUPERVISOR_ROL = 3
export const OPERADOR_ROL = 2
export const MASTER_ROL = 1
export const LIDEROP_ROL = 17
export const ADMINISTRATIVO_ROL = 13
export const DIRECTOR_OPERACIONES = 7
export const COORDINADOR_REGION = 11
export const ANALISTA_ADMIN = 22

export const GERENTE = 4
export const CLIENTE = 5
export const MONITOREO = 6
export const ADMINISTRATIVO = 13
export const CONTABILIDAD_SUPLY_LOGISTICS = 15
export const OPERADOR = 2


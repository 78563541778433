import React, { createContext } from "react";
import usePlantaFase from "../hooks/usePlantaFase";

export const ContextPlantaFase = createContext()

export const ContextPlantaFaseProvider = ({ children }) => {

    const { plantaFases, selectOnePlantaFase, loading, error, list, update, getOne, cleanGetOne, plantasFasesForOpe, getOneOPlantaFaseForGUID_OPE } = usePlantaFase()

    return (
        <ContextPlantaFase.Provider value={{ plantaFases, selectOnePlantaFase, loading, error, list, update, getOne, cleanGetOne, plantasFasesForOpe, getOneOPlantaFaseForGUID_OPE }}>
            {children}
        </ContextPlantaFase.Provider>
    )
}
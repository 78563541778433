import URL_ROUTE from "../constants/index.js"
let token = sessionStorage.getItem('token');

const UsuarioService = () => {

    const listarUsuarios = async () => {
        if (!token) return
        const response = await fetch(URL_ROUTE.LISTARUSUARIOS, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
        return await response.json();
    }

    const updateUsuarios = async ({ ID, ID_EMPRESA,
        ID_CLIENTE,
        ID_PLANTA,
        ID_ROL,
        NOMBRE,
        APELLIDO,
        ESTADO,
        CCDS_REGIONES_ID, EMAIL,
        PASSWORD,
        NUMERO_IDENTIFICACION,
        ID_CARGO,
        ID_TIPO_DE_IDENTIFICACION }) => {
        try {
            const response = await fetch(URL_ROUTE.UPDATEUSUARIOS, {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    ID, ID_EMPRESA,
                    ID_CLIENTE,
                    ID_PLANTA,
                    ID_ROL,
                    NOMBRE,
                    APELLIDO,
                    ESTADO,
                    CCDS_REGIONES_ID, EMAIL,
                    PASSWORD,
                    NUMERO_IDENTIFICACION,
                    ID_CARGO,
                    ID_TIPO_DE_IDENTIFICACION
                })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    };

    const crearUsuarios = async ({ ID_EMPRESA,
        ID_CLIENTE,
        ID_PLANTA,
        ID_ROL,
        NOMBRE,
        APELLIDO,
        ESTADO,
        CCDS_REGIONES_ID, EMAIL,
        PASSWORD,
        NUMERO_IDENTIFICACION,
        ID_CARGO,
        ID_TIPO_DE_IDENTIFICACION }) => {
        try {
            const response = await fetch(URL_ROUTE.CREATEUSUARIOS, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    ID_EMPRESA,
                    ID_CLIENTE,
                    ID_PLANTA,
                    ID_ROL,
                    NOMBRE,
                    APELLIDO,
                    ESTADO,
                    CCDS_REGIONES_ID,
                    EMAIL,
                    PASSWORD,
                    NUMERO_IDENTIFICACION,
                    ID_CARGO,
                    ID_TIPO_DE_IDENTIFICACION
                })
            });

            return await response.json();
        } catch (error) {

            console.error(error.message);
            throw error;
        }
    }

    const saberSiEstaOcupado = async ({ ID_USUARIO }) => {
        try {
            const response = await fetch(URL_ROUTE.SABERSIESTAOCUPADO, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    ID_USUARIO
                })
            });

            return await response.json();
        } catch (error) {

            console.error(error.message);
            throw error;
        }
    }

    const createUserMasivo = async ({ usuarios }) => {
        try {
            const response = await fetch(URL_ROUTE.CREATEUSERMASIVO, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ usuarios })
            });

            return await response.json();
        } catch (error) {

            console.error(error.message);
            throw error;
        }
    }

    const getUsuariosForPlanta = async ({ ID_PLANTA }) => {
        try {
            const response = await fetch(URL_ROUTE.GETFORPLANTAUSUARIOSOLOPLANTA, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ ID_PLANTA })
            });

            return await response.json();
        } catch (error) {

            console.error(error.message);
            throw error;
        }
    }



    return ({
        listarUsuarios,
        updateUsuarios,
        crearUsuarios,
        saberSiEstaOcupado,
        createUserMasivo,
        getUsuariosForPlanta
    })
}

export default UsuarioService

/*         EMAIL: Joi.string().email().required(),
        PASSWORD: Joi.string().required(),
        NUMERO_IDENTIFICACION: Joi.string().required(),
        ID_CARGO: Joi.number().required(),
        ID_TIPO_DE_IDENTIFICACION: Joi.number().required(), */
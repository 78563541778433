
import URL_ROUTE from "../constants/index.js"
let token = sessionStorage.getItem('token');

const OperacionesService = () => {

    const createOperation = async ({ ID_USUARIO,
        ID_DEPENDENCIA,
        ID_TIPO_DE_OPERACION,
        NRO_TONELADAS,
        ID_PLANTA_DESTINO,
        ID_TRANSPORTISTA,
        PLACA,
        CONTENEDOR,
        ID_TIPO_TRANSPORTE,
        FECHO_INICIO,
        FECHO_FIN }) => {
        try {
            const response = await fetch(URL_ROUTE.CREATEOPERACION, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    ID_USUARIO,
                    ID_DEPENDENCIA,
                    ID_TIPO_DE_OPERACION: parseInt(ID_TIPO_DE_OPERACION),
                    NRO_TONELADAS,
                    ID_PLANTA_DESTINO,
                    ID_TRANSPORTISTA,
                    PLACA,
                    CONTENEDOR,
                    ID_TIPO_TRANSPORTE,
                    FECHO_INICIO,
                    FECHO_FIN
                })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }
    }

    const getFiltro = async ({ REGION, PLANTA, FECHADESDE, FECHAHASTA, FILTROS, PLACA }) => {
        try {
            const response = await fetch(URL_ROUTE.GETFILTRO, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    REGION,
                    PLANTA,
                    FECHADESDE,
                    FECHAHASTA,
                    FILTROS,
                    PLACA
                })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }

    }

    const getOperacionForUUID = async (UUID) => {
        try {
            const response = await fetch(URL_ROUTE.getOperacionForUUID, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ UUID })

            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }

    }

    const getOperacionForUUID2 = async (UUID) => {
        try {
            const response = await fetch(URL_ROUTE.GETOPERACIONFORUUID2, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ UUID })
            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }

    }

    const CCDS_SP_ELIMINA_REGISTRO = async (UUID) => {
        try {
            const response = await fetch(URL_ROUTE.CCDS_SP_ELIMINA_REGISTRO, {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ GUID: UUID })

            });

            return await response.json();
        } catch (error) {
            console.error(error.message);
            throw error;
        }

    }

    return { createOperation, getFiltro, getOperacionForUUID, CCDS_SP_ELIMINA_REGISTRO, getOperacionForUUID2 }
}

export default OperacionesService